<template>
    <div class="pa-5">
        <v-card>
            <v-card-text class="grey lighten-2 pa-2">
                <v-btn x-small text @click="redirect('Jobs')"><v-icon>mdi-arrow-left</v-icon></v-btn>&nbsp;
                <strong>JOB SUBMISSION FORM</strong>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <strong>CLIENT DETAILS</strong>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field dense 
                            hide-details 
                            label="Client Name" 
                            outlined
                            v-model="job.client_name">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-select dense 
                            hide-details 
                            label="Priority" 
                            outlined
                            :items="bph_priorities"
                            v-model="job.priority">
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense hide-details label="Client Email" outlined 
                            :value="$store.getters.getUser.email"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field dense hide-details 
                        label="Contact Number" outlined
                        v-model="job.client_ref_no">
                    </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <strong>JOB DETAILS</strong>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select dense 
                            hide-details 
                            label="Job Type Request" 
                            outlined
                            :items="job_types"
                            item-value="id"
                            item-text="job_type"
                            v-model="job.job_type_id">
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-text-field dense hide-details 
                            label="Job Number" 
                            outlined
                            v-model="job.job_number">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense hide-details 
                            label="Job Address" 
                            outlined
                            v-model="job.job_address"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select dense 
                            hide-details 
                            label="NCC Compliance" 
                            outlined
                            :items="['2019', '2022 - WOH']"
                            v-model="job.version">
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea dense hide-details 
                            label="Notes" 
                            outlined
                            v-model="job.notes"></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-2 pa-2">
                                <v-row>
                                    <v-col>
                                        <strong>ZIP Files</strong>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-btn small color="grey darken-3 mr-3 white--text" @click="addZipFile()">
                                            <v-icon>mdi-file-plus</v-icon> file
                                        </v-btn>
                                        <v-btn small color="red darken-2 white--text" @click="addZipUrl()"> 
                                            <v-icon>mdi-link</v-icon> URL
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <div class="pb-2" v-for="d, idx in zips" :key="d.id">
                                    <v-file-input
                                        outlined
                                        prepend-icon="mdi-file-plus"
                                        append-icon="mdi-close"
                                        show-size
                                        dense
                                        hide-details
                                        label="Zip File"
                                        v-model="d.file"
                                        v-if="d.type == 'file'"
                                        @click:append="removeZip(idx)"
                                    ></v-file-input>
                                    <v-text-field
                                        outlined
                                        dense
                                        hide-details
                                        prepend-icon="mdi-link"
                                        append-icon="mdi-close"
                                        label="Zip URL"
                                        v-model="d.file"
                                        v-if="d.type == 'link'"
                                        @click:append="removeZip(idx)"
                                    ></v-text-field>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-2 pa-2">
                                <v-row>
                                    <v-col>
                                        <strong>Plan Files</strong>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-btn small color="grey darken-3 mr-3 white--text" @click="addPlanFile()">
                                            <v-icon>mdi-file-plus</v-icon> file
                                        </v-btn>
                                        <v-btn small color="red darken-2 white--text" @click="addPlanUrl()"> 
                                            <v-icon>mdi-link</v-icon> URL
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <div class="pb-2" v-for="d, idx in plans" :key="d.id">
                                    <v-file-input
                                        outlined
                                        prepend-icon="mdi-file-plus"
                                        append-icon="mdi-close"
                                        show-size
                                        dense
                                        hide-details
                                        label="Plan File"
                                        v-model="d.file"
                                        v-if="d.type == 'file'"
                                        @click:append="removePlan(idx)"
                                    ></v-file-input>
                                    <v-text-field
                                        outlined
                                        dense
                                        hide-details
                                        prepend-icon="mdi-link"
                                        append-icon="mdi-close"
                                        label="Plan URL"
                                        v-model="d.file"
                                        v-if="d.type == 'link'"
                                        @click:append="removePlan(idx)"
                                    ></v-text-field>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-text class="grey lighten-2 pa-2">
                                <v-row>
                                    <v-col>
                                        <strong>Clipsan Trade Plan Files</strong>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-btn small color="grey darken-3 mr-3 white--text" @click="addTradePlanFile()">
                                            <v-icon>mdi-file-plus</v-icon> file
                                        </v-btn>
                                        <v-btn small color="red darken-2 white--text" @click="addTradePlanUrl()"> 
                                            <v-icon>mdi-link</v-icon> URL
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <div class="pb-2" v-for="d, idx in trade_plans" :key="d.id">
                                    <v-file-input
                                        outlined
                                        prepend-icon="mdi-file-plus"
                                        append-icon="mdi-close"
                                        show-size
                                        dense
                                        hide-details
                                        label="Plan File"
                                        v-model="d.file"
                                        v-if="d.type == 'file'"
                                        @click:append="removeTradePlan(idx)"
                                    ></v-file-input>
                                    <v-text-field
                                        outlined
                                        dense
                                        hide-details
                                        prepend-icon="mdi-link"
                                        append-icon="mdi-close"
                                        label="Plan URL"
                                        v-model="d.file"
                                        v-if="d.type == 'link'"
                                        @click:append="removeTradePlan(idx)"
                                    ></v-text-field>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="indigo" 
                    class="darken-4 white--text"
                    :disabled="$store.state.loading"
                    @click="submit()">SUBMIT JOB REQUEST
                </v-btn>
            </v-card-actions>
        </v-card>

    </div>
</template>
<script>
import {globalMixins} from '@/mixins/globalMixins.js';
import axios from 'axios';
import Dialog from '@/components/Dialog.vue';
export default {
    name : 'NewJob',
    mixins : [globalMixins],
    components : {
        Dialog
    },
    data() {
        return {
            user : {},
            job : {},
            plans : [],
            zips : [],
            trade_plans : [],
            companies : [],
            job_types : [],
            states : [],
            show_dialog : false,
            dialog_props : {
                width : 300,
                color : 'red',
                title : '',
                text : '',
                dark : true
            }
        }
    },
    mounted() {
        this.user = this.$store.getters.getUser;
        
        if(this.user.category_id == 1) {
            this.$router.push({name : 'newJob'});
        }

        axios({
            url : this.$store.state.api_url + '/categories/' + this.user.category_id + '/job-types',
            method : 'GET',
            headers : {
                'Authorization' : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {
            this.job_types = res.data.data;
        }).catch(err => {
            console.log(error);
        });

        
        axios({
            url : process.env.VUE_APP_API_URL + '/categories/' + this.user.category_id + '/companies',
            method : 'GET',
            headers : {
                Authorization : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {
            let output = res.data;
            this.companies = output.data;
        });

        this.states = [
            {state : 'SA'},
            {state : 'WA'},
            {state : 'VIC'},
            {state : 'ACT'},
            {state : 'NSW'},
            {state : 'QLD'},
            {state : 'NT'},
            {state : 'TAS'}
        ];
    },
    methods: {
        addZipFile() {
            this.zips.push({
                type : 'file',
                filename : null
            });
        },
        addZipUrl() {
            this.zips.push({
                type : 'link',
                filename : ''
            });
        },
        removeZip(idx) {
            this.zips.splice(idx, 1);
        },
        addTradePlanFile() {
            this.trade_plans.push({
                type : 'file',
                filename : null
            });
        },
        addTradePlanUrl() {
            this.trade_plans.push({
                type : 'link',
                filename : ''
            });
        },
        removeTradePlan(idx) {
            this.trade_plans.splice(idx, 1);
        },
        addPlanFile() {
            this.plans.push({
                type : 'file',
                filename : null
            });
        },
        addPlanUrl() {
            this.plans.push({
                type : 'link',
                filename : ''
            });
        },
        removePlan(idx) {
            this.plans.splice(idx, 1);
        },
        submit() {
            this.$store.dispatch('loading',true);
            let data = new FormData();
            
            data.append('category_id', this.user.category_id);
            data.append('job_type_id', this.job.job_type_id);
            data.append('priority', this.job.priority);
            data.append('job_address', this.job.job_address);
            data.append('notes', this.job.notes);

            if(this.job.lbs_ref_no) {
                data.append('lbs_ref_no', this.job.lbs_ref_no);
            }

            if(this.job.client_name) {
                data.append('client_name', this.job.client_name);
            }

            if(this.job.job_number) {
                data.append('job_number', this.job.job_number);
            }

            if(this.job.client_ref_no) {
                data.append('client_ref_no', this.job.client_ref_no);
            }

            if(this.job.state) {
                data.append('state', this.job.state);
            }

            if(this.job.version) {
                data.append('version', this.job.version);
            }

            this.plans.forEach((p, idx) => {
                data.append('plans['+ idx +'][file]', p.file);
                data.append('plans['+ idx +'][type]', p.type);
            });

            this.zips.forEach((d, idx) => {
                data.append('zips['+ idx +'][file]', d.file);
                data.append('zips['+ idx +'][type]', d.type);
            });

            this.trade_plans.forEach((d, idx) => {
                data.append('trade_plans['+ idx +'][file]', d.file);
                data.append('trade_plans['+ idx +'][type]', d.type);
            });

            axios({
                url : process.env.VUE_APP_API_URL + '/jobs',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'multipart/form-data',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.redirect('Jobs');
                this.$store.dispatch('loading', false);
            }).catch(err => {
                if(err.response.status == 400) {
                    // this.dialog_props.title = err.response.statusText;
                    // this.dialog_props.text = err.response.data.errors.join("\n");
                    alert(err.response.data.errors.join("\n"));
                }
                else {
                    alert(JSON.stringify(err.response));
                }

                this.$store.dispatch('loading', false);
            });
        }
    }
}
</script>