<template>
    <div class="pa-5">
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-btn-toggle v-model="job_status">
                    <v-btn small 
                        key="allocated"
                        @click="changeStatus('allocated')"
                        :color="job_status == 'allocated' ? 'active' : ''">
                        Allocated ({{ all_jobs['allocated'].length || 0 }})
                    </v-btn>

                    <v-btn small 
                        key="completed"
                        @click="changeStatus('completed')"
                        :color="job_status == 'completed' ? 'active' : ''">
                        Completed ({{ all_jobs['completed'].length || 0 }})
                    </v-btn>

                    <v-btn small 
                        key="cancelled"
                        @click="changeStatus('cancelled')"
                        :color="job_status == 'cancelled' ? 'active' : ''">
                        Cancelled({{ all_jobs['cancelled'].length || 0 }})
                    </v-btn>
                    
                </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <div class="text-right">
                    <v-btn small color="indigo" class="mb-3" dark @click="redirect('NewJob')">Create Job Request</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-card>
            <v-card-text>
                <v-row align="center">
                    <v-col>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="datefrom"
                                    label="From"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="datefrom"
                            @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateto"
                                label="To"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="dateto"
                            @input="menu2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-data-table
                :headers="headers"
                :items="jobs"
                class="elevation-1 table-sm"
                :search="search"
                >
                <template v-slot:item="{item}">
                    <tr v-if="$store.getters.getUser.category_id == 1">
                        <td>{{ item.date_submitted }}</td>
                        <td>{{ item.priority }}</td>
                        <td>{{ item.job_type }}</td>
                        <td>{{ item.lbs_ref_no }}</td>
                        <td>{{ item.client_ref_no }}</td>
                        <td>{{ item.company }}</td>
                        <td>
                            <div :title="item.client.company">{{ item.client.name }}</div>
                        </td>
                        <!-- <td>{{ item.job_address.length > 15 ? item.job_address.substring(0, 20) + '...' : item.job_address }}</td> -->
                        <td>{{ item.job_status }}</td>
                        <td>{{ item.staff ? item.staff.name : '' }}</td>
                        <td>
                            <div v-if="item.job_status != 'completed'">{{ item.estimated_completion_date }}</div>
                            <div v-if="item.job_status == 'completed'" :title="'Estimated : ' + item.estimated_completion_date">{{ item.completed_at }}</div>
                        </td>
                        <td>
                            <v-btn small icon color="indigo"
                                @click="viewJobDetails(item)">
                                <v-icon>
                                    mdi-information
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    <tr v-if="$store.getters.getUser.category_id > 1">
                        <td>{{ item.date_submitted }}</td>
                        <td>{{ item.priority }}</td>
                        <td>{{ item.version }}</td>
                        <td>{{ item.job_type }}</td>
                        <td>{{ item.job_number }}</td>
                        <td>{{ item.company }}</td>
                        <td>{{ item.job_note?.star_rating }}</td>
                        <td>{{ item.job_note?.climate_zone }}</td>
                        <td>{{ item.job_address }}</td>
                        <td>{{ item.staff?.name }}</td>
                        <td>{{ item.job_status }}</td>
                        <td>
                            <v-btn small icon color="indigo"
                                @click="viewJobDetails(item)">
                                <v-icon>
                                    mdi-information
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <bottom-sheet-job-details 
            :timelines="job_timelines"
            :details="job" 
            :show.sync="show_details">
        </bottom-sheet-job-details>
    </div>
</template>
<style scoped>
    .table-sm tr td {
        font-size: 12px !important;
    }
</style>
<script>
    import axios from 'axios';
    import { globalMixins } from '@/mixins/globalMixins';
    import BottomSheetJobDetails from '@/components/BottomSheetJobDetails.vue';
    export default {
        name : 'ListView',
        mixins : [globalMixins],
        components : {
            BottomSheetJobDetails
        },
        data() {
            return {
                menu : null,
                menu2 : null,
                all_jobs : [],
                job_status : 'allocated',
                search: '',
                datefrom : null,
                dateto : null,
                show_details : false,
                headers : [],
                jobs : [],
                job : {},
                job_timelines : []
            }
        },
        watch: {
            'datefrom' : function (newVal, oldVal) {
                this.reload();
            },
            'dateto' : function (newVal, oldVal) {
                this.reload();
            }
        },
        mounted() {
            this.user = this.$store.getters.getUser;

            let date = new Date();
            this.dateto = this.formatDate(new Date(date));

            let datefrom = date.setDate(date.getDate() - 180);
            this.datefrom = this.formatDate(new Date(datefrom));
            this.reload();

            if(this.$store.getters.getUser.category_id == 1) {
                this.headers = this.lbs_headers;
            }
            else if(this.$store.getters.getUser.category_id > 1) {
                this.headers = this.bph_headers;
            }
        },
        methods : {
            reload() {
                axios({
                    url : process.env.VUE_APP_API_URL + '/client/jobs',
                    method : 'GET',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.getters.getToken
                    },
                    params : {
                        starttime : this.datefrom,
                        endtime : this.dateto
                    }
                }).then(response => {
                    this.all_jobs = response.data;
                    this.jobs = this.all_jobs[this.job_status];
                });
            },
            redirect(page) {
                this.$router.push({name : page});
            },
            changeStatus(status) {
                this.job_status = status;
                this.jobs = this.all_jobs[this.job_status];
            },
            // viewJobDetails(job) {
            //     this.$store.dispatch('loading', true);
            //     this.job = job;
            //     axios({
            //         url : process.env.VUE_APP_API_URL + '/jobs/' + job.id + '/timeline',
            //         method : 'GET',
            //         headers : {
            //             'Accept' : 'application/json',
            //             'Authorization' : 'Bearer ' + this.$store.getters.getToken
            //         }
            //     }).then(response => {
            //         this.job_timelines = response.data;
            //         this.show_details = true;

            //         this.$store.dispatch('loading', false);
            //     }).catch(err => {
            //         this.$store.dispatch('loading', false);
            //     });
            // }
            viewJobDetails(job) {
                if(job.category_id == 1) {
                    this.$router.push({name : 'JobDetails', params : {id : job.id}});
                }
                else if(job.category_id > 1) {
                    this.$router.push({name : 'JobDetailsBPH', params : {id : job.id}});
                }
                
            }
        }
    }
</script>