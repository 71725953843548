<template>
    <div class="pa-5">
        <v-card width="800">
            <v-card-title>My Profile</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-text-field dense label="Name" outlined v-model="user.name" @blur="saveChanges()"></v-text-field>
                <v-text-field dense label="Email" outlined v-model="user.email" readonly></v-text-field>
                <v-text-field dense label="Accout Type" outlined v-model="user.account_type" readonly></v-text-field>
                <v-checkbox
                    v-model="user.email_on_create"
                    :true-value="1"
                    :false-value="0"
                    label="Send me a notification after job request submission"
                    @change="saveChanges()"
                    >
                </v-checkbox>
                <v-checkbox
                    dense
                    v-model="user.email_on_complete"
                    :true-value="1"
                    :false-value="0"
                    @change="saveChanges()"
                    label="Send me a notification when my job request is completed"
                ></v-checkbox>
                <v-divider></v-divider>
                <v-checkbox
                    dense
                    v-model="change_password"
                    label="Change Password"
                ></v-checkbox>
                <div v-if="change_password">
                    <v-text-field dense label="Current Password" v-model="password.password" outlined type="password"></v-text-field>
                    <v-text-field dense label="New Password" v-model="password.new_password" outlined type="password"></v-text-field>
                    <v-text-field dense label="Confirm New Password" v-model="password.new_password_confirmation" outlined type="password"></v-text-field>
                    <div class="text-right">
                        <v-btn color="primary" small @click="changePassword()">Update Password</v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <v-snackbar v-model="snackbar" timeout="1000">
            {{ snackbar_text }}
        </v-snackbar>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name : 'Profile',
    data() {
        return {
            init_user : {},
            user : {},
            password : {},
            change_password : false,
            snackbar : false,
            snackbar_text : 'Success'
        }
    },
    mounted() {
        axios({
            url : process.env.VUE_APP_API_URL + '/my-profile',
            method : 'GET',
            headers : {
                Authorization : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {
            let output = res.data;
            this.user = output.data;
            this.init_user = Object.assign({}, output.data);
        }).catch(err => {
            alert(JSON.stringify(err));
        });
    },
    methods: {
        saveChanges() {
            if(JSON.stringify(this.init_user) == JSON.stringify(this.user)) {
                return;
            }
            axios({
                url : process.env.VUE_APP_API_URL + '/my-profile/' + this.user.id,
                method : 'PUT',
                data : this.user,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                } 
            }).then(res => {
                this.snackbar = true;
                this.snackbar_text = 'Profile has been updated';
            }).catch(err => {
                alert(JSON.stringify(err));
            })
        },
        changePassword() {
            this.$store.state.loading = true;
            axios({
                url : process.env.VUE_APP_API_URL + '/my-profile/change-password',
                method : 'PUT',
                data : this.password,
                headers : {
                    Authorization : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                alert(output.message);
                this.change_password = false;
            }).catch(err => {
                if(err.response.status == 400) {
                    alert(err.response.data.data.join("\n"));
                }
            })
        }
    }
}
</script>