<template>
    <div class="pa-5">
        <v-card>
            <v-card-text class="grey lighten-2 pa-2">
                <v-btn x-small text @click="redirect('Jobs')"><v-icon>mdi-arrow-left</v-icon></v-btn>&nbsp;
                <strong>JOB DETAILS</strong>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field label="LBS Ref #" outlined dense v-model="job.lbs_ref_no" hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field label="Client Ref #" outlined dense v-model="job.client_ref_no" hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select label="NCC Compliance" outlined dense v-model="job.version" hide-details :items="['NCC2019', 'NCC2022 (WHO)']"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="User" readonly outlined dense v-model="$store.getters.getUser.name"></v-text-field>
                    </v-col>
                    <v-col>
                        <!-- <v-text-field label="Company" readonly outlined dense v-model="$store.getters.getUser.company"></v-text-field> -->
                        <v-select label="Account Client" outlined dense :items="companies" item-value="id" item-text="company_name" v-model="job.company_id"></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-5">
            <v-card-text class="grey lighten-2 pa-2">
                <strong>JOB DETAILS</strong>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="8">
                        <v-text-field v-model="job.job_address" outlined label="Job Address" dense hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-select
                            :items="states"
                            item-text="state"
                            item-value="state"
                            label="State"
                            outlined
                            hide-details
                            dense
                            v-model="job.state"
                            >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="8" sm="12">
                        <v-select
                            :items="job_types"
                            item-text="job_type"
                            item-value="id"
                            label="Job Type"
                            outlined
                            dense
                            v-model="job.job_type_id"
                            >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-select
                            :items="priorities"
                            label="Priority"
                            outlined
                            dense
                            v-model="job.priority"
                            >
                        </v-select>
                    </v-col>
                </v-row>
                <v-textarea label="Notes" dense outlined v-model="job.notes"></v-textarea>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-2 pa-2">
                                <v-row>
                                    <v-col>
                                        <strong>PLANS</strong>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-btn small color="grey darken-3 mr-3 white--text" @click="addPlanFile()">
                                            <v-icon>mdi-file-plus</v-icon> file
                                        </v-btn>
                                        <v-btn small color="red darken-2 white--text" @click="addPlanUrl()"> 
                                            <v-icon>mdi-link</v-icon> URL
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <div class="pb-2" v-for="p, idx in plans" :key="p.id">
                                    <v-file-input
                                        prepend-icon="mdi-file-plus"
                                        append-icon="mdi-close"
                                        dense
                                        hide-details
                                        outlined
                                        show-size
                                        label="Plan File"
                                        v-model="p.file"
                                        @click:append="removePlan(idx)"
                                        v-if="p.type == 'file'"
                                    ></v-file-input>
                                    <v-text-field
                                        dense
                                        hide-details
                                        outlined
                                        prepend-icon="mdi-link"
                                        append-icon="mdi-close"
                                        label="Plan URL"
                                        v-model="p.file"
                                        @click:append="removePlan(idx)"
                                        v-if="p.type == 'link'"
                                    ></v-text-field>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-2 pa-2">
                                <v-row>
                                    <v-col>
                                        <strong>DOCUMENTS</strong>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-btn small color="grey darken-3 mr-3 white--text" @click="addDocumentFile()">
                                            <v-icon>mdi-file-plus</v-icon> file
                                        </v-btn>
                                        <v-btn small color="red darken-2 white--text" @click="addDocumentUrl()"> 
                                            <v-icon>mdi-link</v-icon> URL
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <div class="pb-2" v-for="d, idx in documents" :key="d.id">
                                    <v-file-input
                                        outlined
                                        prepend-icon="mdi-file-plus"
                                        append-icon="mdi-close"
                                        show-size
                                        dense
                                        hide-details
                                        label="Document File"
                                        v-model="d.file"
                                        v-if="d.type == 'file'"
                                        @click:append="removeDocument(idx)"
                                    ></v-file-input>
                                    <v-text-field
                                        outlined
                                        dense
                                        hide-details
                                        prepend-icon="mdi-link"
                                        append-icon="mdi-close"
                                        label="Document URL"
                                        v-model="d.file"
                                        v-if="d.type == 'link'"
                                        @click:append="removeDocument(idx)"
                                    ></v-text-field>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="indigo" 
                    class="darken-4 white--text" 
                    @click="submit()"
                    :disabled="$store.state.loading">
                    <v-icon>{{ $store.state.loading ? 'mdi-loading mdi-spin' : 'mdi-check'}}</v-icon>
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
        <Dialog v-bind:show.sync="show_dialog" 
            width="400" 
            color="red"
            :props="dialog_props">
        </Dialog>
    </div>
</template>
<script>
import {globalMixins} from '@/mixins/globalMixins.js';
import axios from 'axios';
import Dialog from '@/components/Dialog.vue';
export default {
    name : 'NewJob',
    mixins : [globalMixins],
    components : {
        Dialog
    },
    data() {
        return {
            user : {},
            job : {},
            plans : [],
            companies : [],
            documents : [],
            job_types : [],
            states : [],
            show_dialog : false,
            dialog_props : {
                width : 300,
                color : 'red',
                title : '',
                text : '',
                dark : true
            }
        }
    },
    mounted() {
        this.user = this.$store.getters.getUser;

        if(this.user.category_id > 1) {
            this.$router.push({name : 'newBPHJob'});
        }

        axios({
            url : this.$store.state.api_url + '/categories/' + this.user.category_id + '/job-types',
            method : 'GET',
            headers : {
                'Authorization' : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {
            this.job_types = res.data.data;
        }).catch(err => {
            console.log(error);
        });

        
        axios({
            url : process.env.VUE_APP_API_URL + '/categories/' + this.user.category_id + '/companies',
            method : 'GET',
            headers : {
                Authorization : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(res => {
            let output = res.data;
            this.companies = output.data;
        });

        this.states = [
            {state : 'SA'},
            {state : 'WA'},
            {state : 'VIC'},
            {state : 'ACT'},
            {state : 'NSW'},
            {state : 'QLD'},
            {state : 'NT'},
            {state : 'TAS'}
        ];
    },
    methods: {
        addDocumentFile() {
            this.documents.push({
                type : 'file',
                filename : null
            });
        },
        addDocumentUrl() {
            this.documents.push({
                type : 'link',
                filename : ''
            });
        },
        removeDocument(idx) {
            this.documents.splice(idx, 1);
        },
        addPlanFile() {
            this.plans.push({
                type : 'file',
                filename : null
            });
        },
        addPlanUrl() {
            this.plans.push({
                type : 'link',
                filename : ''
            });
        },
        removePlan(idx) {
            this.plans.splice(idx, 1);
        },
        submit() {
            this.$store.dispatch('loading',true);
            let data = new FormData();
            
            data.append('lbs_ref_no', this.job.lbs_ref_no);
            data.append('category_id', this.user.category_id);
            data.append('company_id', this.job.company_id);
            data.append('job_type_id', this.job.job_type_id);
            data.append('priority', this.job.priority);
            data.append('job_address', this.job.job_address);
            data.append('notes', this.job.notes);

            if(this.job.client_ref_no) {
                data.append('client_ref_no', this.job.client_ref_no);
            }

            if(this.job.state) {
                data.append('state', this.job.state);
            }

            if(this.job.version) {
                data.append('version', this.job.version);
            }

            if(this.job.version) {
                data.append('notes', this.job.notes);
            }

            this.plans.forEach((p, idx) => {
                data.append('plans['+ idx +'][file]', p.file);
                data.append('plans['+ idx +'][type]', p.type);
            });

            this.documents.forEach((d, idx) => {
                data.append('documents['+ idx +'][file]', d.file);
                data.append('documents['+ idx +'][type]', d.type);
            });

            axios({
                url : process.env.VUE_APP_API_URL + '/jobs',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'multipart/form-data',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.redirect('Jobs');
                this.$store.dispatch('loading', false);
            }).catch(err => {
                if(err.response.status == 400) {
                    // this.dialog_props.title = err.response.statusText;
                    // this.dialog_props.text = err.response.data.errors.join("\n");
                    alert(err.response.data.errors.join("\n"));
                }
                else {
                    alert(JSON.stringify(err.response));
                }

                this.$store.dispatch('loading', false);
            });
        }
    }
}
</script>